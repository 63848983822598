<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    open-delay="300"
    persistent
    scrollable
    transition="dialog-top-transition"
  >
    <template #activator="{ on }">
      <v-toolbar flat>
        <v-toolbar-title v-text="$t('invoice.references')" />
        <v-spacer />
        <v-btn v-if="!disabled" color="primary" depressed v-on="on">
          <icon-add class="mr-2" size="1.5em" />
          {{ $t("set.invoice.references") }}
        </v-btn>
      </v-toolbar>
    </template>

    <v-card v-if="dialog">
      <v-toolbar color="grey lighten-3">
        <v-toolbar-title>{{ $t("invoice.references") }}</v-toolbar-title>

        <template #extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-if="!globalOnly" v-text="$t('invoices')" />
            <v-tab v-if="!withoutGlobals" v-text="$t('global')" />
          </v-tabs>
        </template>
      </v-toolbar>

      <v-card-text class="mt-4">
        <v-tabs v-model="tab">
          <v-tab-item v-if="!globalOnly">
            <references-invoices-search />
          </v-tab-item>
          <v-tab-item v-if="!withoutGlobals">
            <reference-global-form v-model="arGlobals" :disabled="disabled" />
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-card-actions>
        <v-btn :disabled="disabled" color="primary" depressed @click="add">
          <icon-add class="mr-2" />
          {{ $t("apply.references") }}
        </v-btn>

        <v-spacer />

        <v-btn color="primary" text @click="close">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {
  Component,
  Mixins,
  Prop,
  Provide,
  Watch,
} from "vue-property-decorator";
import type {
  InvoicePositionData,
  InvoiceReference,
} from "@planetadeleste/vue-mc-gw";
import { Invoice, InvoiceMovementType } from "@planetadeleste/vue-mc-gw";
import InvoiceWithReferencesMixin from "@/modules/invoices/mixins/InvoiceWithReferencesMixin";
import CacheMixin from "@/mixins/CacheMixin";

import ReferencesInvoicesSearch from "@/modules/invoices/components/references/ReferencesInvoicesSearch.vue";
import ReferenceGlobalForm from "@/modules/invoices/components/references/ReferenceGlobalForm.vue";
import { EventBus } from "@/services/event-bus";
import IconAdd from "@/components/icons/IconAdd.vue";
import {
  chain,
  filter,
  find,
  findIndex,
  forEach,
  get,
  isEmpty,
  isNull,
  map,
  set,
} from "lodash";
import { number } from "mathjs";
import useMath from "@/composables/math";
import type { InvoiceData } from "@/types/utils";
import { ref } from "@/services/Utils";

const { sumBy, math } = useMath();

@Component({
  components: {
    IconAdd,
    ReferencesInvoicesSearch,
    ReferenceGlobalForm,
  },
})
export default class ReferencesDialog extends Mixins(
  InvoiceWithReferencesMixin,
  CacheMixin
) {
  @Prop(Boolean) readonly disabled!: boolean;
  @Provide() obSelected = ref<InvoiceData[]>([]);

  dialog = false;
  arGlobals: InvoiceReference[] = [];
  tab: number[] = [0];

  get globals() {
    return filter(this.references, { is_global: true });
  }

  get withInvoices() {
    return filter(this.references, { is_global: false });
  }

  get arSelected() {
    return this.obSelected.value;
  }

  /**
   * Invoices type e-Resguardo only use global references
   * @returns {Boolean}
   */
  get globalOnly(): boolean {
    return this.iMovementType === InvoiceMovementType.CODE_RECEIPT;
  }

  /**
   * Invoices of type e-Recibo do not show global references
   * @returns {Boolean}
   */
  get withoutGlobals(): boolean {
    return (
      // this.isReceiptType ||
      this.iMovementType === InvoiceMovementType.CODE_RECEIPT_CORRECT
    );
  }

  /**
   * Total price set manually on debt (cobranza RYA)
   * @returns {Number}
   */
  get fPriceLimit(): number {
    return this.isDebtRYA ? number(this.obInvoice.get("price_limit", 0)) : 0;
  }

  get fSelectedAmount(): number {
    return this.arSelected.length
      ? sumBy(this.arSelected, (obItem) => obItem.amount ?? 0)
      : 0;
  }

  @Watch("dialog")
  onOpen(bValue: boolean) {
    if (!bValue) {
      return;
    }

    // Preload selected reference invoices
    if (this.withInvoices.length) {
      this.obSelected.value = [];

      forEach(this.withInvoices, (obReference) => {
        if (
          isNull(obReference) ||
          isEmpty(obReference) ||
          !obReference.invoice_ref_id
        ) {
          return;
        }

        // Check if reference has invoice data
        const obInvoiceData = get(obReference, "invoice");
        if (obInvoiceData) {
          this.obSelected.value.push(
            this.setSelectedInvoicePositions(
              obInvoiceData,
              obReference.invoice_id
            )
          );
          return;
        }

        // Load invoice data from api
        const obInvoice = new Invoice({ id: obReference.invoice_ref_id });
        obInvoice.fetch().then((response) => {
          if (!response || !response.getData()) {
            return;
          }

          this.obSelected.value.push(
            this.setSelectedInvoicePositions(
              response.getData().data,
              obReference.invoice_id
            )
          );
        });
      });
    }

    // preload from positions on Debt type invoice
    if (
      [InvoiceMovementType.CODE_DEBT, 15].includes(this.iMovementType) &&
      this.positions.length
    ) {
      forEach(this.positions, (obPosition) => {
        if (!obPosition.item_id) {
          return;
        }

        if (
          !this.arSelected.length ||
          !find(this.arSelected, { id: obPosition.item_id })
        ) {
          const obInvoice = new Invoice({ id: obPosition.item_id });
          obInvoice.fetch().then((response) => {
            if (!response || !response.getData()) {
              return;
            }

            this.obSelected.value.push(
              this.setSelectedInvoicePositions(
                response.getData().data,
                obPosition.item_id
              )
            );
          });
        }
      });
    }

    if (this.globals) {
      this.arGlobals = [...this.globals];
    }
  }

  async add() {
    // Prompt to equalize values for debt rya invoices
    if (
      this.isDebtRYA &&
      // this.fPriceLimit &&
      this.fSelectedAmount !== this.fPriceLimit
    ) {
      const fBalance =
        this.fPriceLimit > 0
          ? math.subtract(this.fPriceLimit, this.fSelectedAmount)
          : math.subtract(this.fSelectedAmount, this.fPriceLimit);
      const sMessage = this.$t("ask.equal.pricelimit", {
        amount: this.fSelectedAmount,
        total: this.fPriceLimit,
        diff: fBalance,
      });
      const bRes = await this.$confirm(sMessage as string);

      if (bRes) {
        this.obInvoice.set("price_limit", this.fSelectedAmount);
      } else if (this.fSelectedAmount > this.fPriceLimit) {
        this.$toast(
          this.$t("message.reference.value.must.be.lessthan.total", {
            amount: this.fSelectedAmount,
            total: this.fPriceLimit,
          })
        );
        return;
      }
    }

    this.resetPaymentMethods();
    this.setGlobalReferences();

    // Remove all references
    if (!this.arSelected.length) {
      if (this.withInvoices.length) {
        const arAllRefList = [...this.withInvoices];
        forEach(arAllRefList, this.removeReference);
      }

      this.addReferences(this.arSelected);
      // this.syncPositions();
      this.close();

      return;
    } else {
      this.removeEmptyPositions();
    }

    // Remove unselected references
    if (this.withInvoices.length) {
      const arReferenceInvoiceListId = map(this.arSelected, "id");
      const arReferenceList = [...this.withInvoices];
      forEach(arReferenceList, (obRefModel) => {
        if (
          obRefModel &&
          obRefModel.invoice_ref_id &&
          !arReferenceInvoiceListId.includes(obRefModel.invoice_ref_id)
        ) {
          this.removeReference(obRefModel);
        }
      });
    }

    this.addReferences(this.arSelected);

    // Copy customer and invoice type from reference to target invoice
    if (this.iMovementType === InvoiceMovementType.CODE_RECEIPT_CORRECT) {
      const obReferenceInvoice = this.arSelected[0];
      this.obInvoice.set("customer_id", obReferenceInvoice.customer_id);
      this.obInvoice.set("customer", obReferenceInvoice.customer);

      if (obReferenceInvoice.customer.firm) {
        this.obInvoice.set("customer_firm", obReferenceInvoice.customer.firm);
      }

      this.obInvoice.set("invoice_type", obReferenceInvoice.invoice_type);
      this.obInvoice.set("invoice_type_id", obReferenceInvoice.invoice_type_id);
    } else if (!this.isCancelDebt) {
      this.addEmptyItemPosition();
    }

    this.close();
  }

  setSelectedInvoicePositions(
    obInvoiceData: InvoiceData,
    iReferencedInvoiceId: number
  ) {
    if (!obInvoiceData.id) {
      return obInvoiceData;
    }

    let arPositions: Partial<InvoicePositionData>[] = get(
      obInvoiceData,
      "positions",
      []
    );

    if (!arPositions.length && this.positions) {
      arPositions = chain(this.positions)
        .filter((obPosition) => {
          return obPosition.invoice_id == iReferencedInvoiceId;
        })
        .map((obPosition) => obPosition.attributes)
        .value();
    }

    if (arPositions.length) {
      const arPositionListId: number[] = [];
      forEach(arPositions, (obPosition) => {
        if (obPosition.ref_id) {
          arPositionListId.push(obPosition.ref_id);
        }
      });

      set(obInvoiceData, "position_id_list", arPositionListId);
    }

    return obInvoiceData;
  }

  setGlobalReferences() {
    EventBus.emit("before.add.global.reference");

    if (this.globals.length) {
      forEach(this.globals, this.removeReference);
    }

    forEach(this.arGlobals, this.setReference);
  }

  removeReference(obModel: InvoiceReference) {
    const index = findIndex(this.references, {
      invoice_ref_id: obModel.invoice_ref_id,
    });

    if (index !== -1) {
      // Remove all item positions
      const arPositions = filter(this.positions, (obPosition) => {
        return (
          !obPosition.invoice_id ||
          obPosition.invoice_id !== obModel.invoice_ref_id
        );
      });
      this.setPositions(arPositions);

      // Remove references from invoice
      const arReferences: InvoiceReference[] = this.references;
      arReferences.splice(index, 1);

      this.obInvoice.set("references", arReferences);
    }
  }

  async getMovementTypeCode(): Promise<number | null> {
    // Check by invoice movemente type (2: Devolucion, 5: Nota debito)
    const invoiceMovementTypeId = this.obInvoice.invoice_movement_type_id;
    const obInvoiceMovementTypeData = this.cache(
      invoiceMovementTypeId,
      "InvoiceMovementType"
    );
    const obInvoiceMovementType = new InvoiceMovementType();
    if (obInvoiceMovementTypeData) {
      obInvoiceMovementType.set(obInvoiceMovementTypeData);
    } else {
      obInvoiceMovementType.set("id", invoiceMovementTypeId);
      await obInvoiceMovementType.fetch();
    }

    return obInvoiceMovementType ? number(obInvoiceMovementType.code) : null;
  }

  close() {
    this.obSelected.value = [];
    this.dialog = false;
  }
}
</script>
